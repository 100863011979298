import Vue from 'vue'
import VueRouter from 'vue-router'
import PPIView from '../views/PPIView.vue'
import CustomerView from '../views/CustomerView.vue';
import CabangView from '../views/CabangView.vue';
import InventoryView from '@/views/InventoryView.vue';
import CabangViewAm from '../views/CabangViewAm.vue';
import BDPView from '../views/BDPView.vue'




Vue.use(VueRouter)

const routes = [
  {
    path: '/customer/:customerId',
    name: 'Customer',
    component: CustomerView,
    props: true,
  }
  ,
  {
    path: '/cabang/:cabangId',
    name: 'Cabang',
    component: CabangView,
    props: true,
  },
  {
    path: '/cabangam/:cabangId',
    name: 'Cabangam',
    component: CabangViewAm,
    props: true,
  }
  ,
  {
    path: '/ppi/:companyId',
    name: 'ppi',
    component: PPIView,
    props: true,
  }
  ,
  {
    path: '/inventory/:cabangId',
    name: 'Inventory',
    component: InventoryView,
    props: true,
  }
  ,
  {
    path: '/ppi',
    name: 'ppi',
    component: PPIView,
    props: true,
  },
  {
    path: '/bdp/:cabangId',
    name: 'bdp',
    component: BDPView,
    props: true,
  },
  
]

const router = new VueRouter({
  mode : 'hash',
  routes
})





export default router



