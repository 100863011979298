<template>
  <v-app>
    <v-container fluid>
      <v-layout row row_login wrap>
        <v-flex xs12 md8 lg10>
          <v-card>
            <v-card-title>
              <h3>Profil Cabang : {{ unit_name }}</h3>
            </v-card-title>
            <v-card-subtitle>
              <h4>Tahun : {{ new Date().getFullYear() }}</h4>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-subtitle>
              <v-row>
                <v-col cols="6">
                  <h4>Sales YTD</h4>
                  <h2>
                    {{ totalrp }}
                  </h2>
                </v-col>
                <v-col cols="6">
                  <h4>Saldo Piutang</h4>
                  <h2>
                    {{ totalpiutang }}
                  </h2>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-divider></v-divider>
            <div id="chartdiv" :style="{ width: '100%', height: '500px' }"></div>
            <v-divider></v-divider>
            <v-card-title>
              <h4>List Divisi Komersil</h4>
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" md="6">
                  <h4>List Sales Group by Divisi Komersil</h4>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="search_table_items_sales_divisi"
                    append-icon="mdi-magnify"
                    label="Divisi"
                    single-line
                    hide-details
                    @input="getSalesByDivisi()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-simple-table fixed-header height="200px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="70%">Divisi</th>
                          <th class="text-center" width="30%">Total Rp</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in items_sales_divisi" :key="item.divisi">
                          <td style="font-size: small">{{ item.divisi }}</td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.rp) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-title>
              <h4>List Sales</h4>
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" md="6">
                  <h4>List Sales Group by Mitra</h4>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="search_table_items_sales"
                    append-icon="mdi-magnify"
                    label="Mitra"
                    single-line
                    hide-details
                    @input="getSalesByMitra()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-simple-table fixed-header height="200px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="30%">Kode</th>
                          <th class="text-center" width="40%">Mitra</th>
                          <th class="text-center" width="30%">Total Rp</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in items_sales" :key="item.partner_id">
                          <td style="font-size: small">
                            {{ item.partner_id }}
                          </td>
                          <td style="font-size: small">
                            {{ item.partner_name }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.rp) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-title>
              <h4>List Saldo Piutang</h4>
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" md="6">
                  <h4>
                    Tagihan Yang Harus Segera Dibayarkan Mitra Agar Tetap Bisa
                    Bertransaksi
                  </h4>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="search_table_items_piutang"
                    append-icon="mdi-magnify"
                    label="Mitra"
                    single-line
                    hide-details
                    @input="getPiutang()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-simple-table fixed-header height="200px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="30%">Kode</th>
                          <th class="text-center" width="40%">Mitra</th>
                          <th class="text-center" width="30%">Total Rp</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in items_piutang" :key="item.customer_account">
                          <td style="font-size: small">
                            {{ item.customer_account }}
                          </td>
                          <td style="font-size: small">
                            {{ item.name }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.rp) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-title>
              <h4>Top Komoditi</h4>
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" md="6">
                  <h4>3 Komoditi terbanyak yang terjual</h4>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="search_table_items_komoditi"
                    append-icon="mdi-magnify"
                    label="Komoditi"
                    single-line
                    hide-details
                    @input="getKomoditi()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-simple-table fixed-header height="200px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="70%">Nama Komoditi</th>
                          <th class="text-center" width="30%">Total Rp</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in items_komoditi" :key="item.customer_account">
                          <td style="font-size: small">
                            {{ item.product_category_name }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.rp) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-title>
              <h4>Top Produk</h4>
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" md="6">
                  <h4>3 Produk terbanyak yang terjual</h4>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="search_table_items_produk"
                    append-icon="mdi-magnify"
                    label="Produk"
                    single-line
                    hide-details
                    @input="getProduk()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-simple-table fixed-header height="200px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="70%">Nama Produk</th>
                          <th class="text-center" width="30%">Total Rp</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in items_produk" :key="item.customer_account">
                          <td style="font-size: small">
                            {{ item.product_name }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.rp) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-title>
              <h4>Top Mitra</h4>
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" md="6">
                  <h4>3 Mitra dengan transaksi terbesar</h4>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="search_table_items_mitra"
                    append-icon="mdi-magnify"
                    label="Mitra"
                    single-line
                    hide-details
                    @input="getMitra()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-simple-table fixed-header height="200px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="70%">Mitra</th>
                          <th class="text-center" width="30%">Total Rp</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in items_mitra" :key="item.customer_account">
                          <td style="font-size: small">
                            {{ item.partner_name }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.rp) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>
  
<script>
import axios from "axios";
import * as Cronitor from "@cronitorio/cronitor-rum";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

Cronitor.load("e4f11e8ee1a7c8261ded903c2fa64d73");
Cronitor.track("SalesCabang");

export default {
  props: ["cabangId"],
  data() {
    return {
      chart: null,
      items_sales_divisi: [],
      search_table_items_sales_divisi: "",

      items_sales: [],
      search_table_items_sales: "",

      items_piutang: [],
      search_table_items_piutang: "",

      items_komoditi: [],
      search_table_items_komoditi: "",

      items_produk: [],
      search_table_items_produk: "",

      items_mitra: [],
      search_table_items_mitra: "",
      totalrp: 0,
      totalpiutang: 0,
      unit_name: "",
      whatsappLink:
        "https://api.whatsapp.com/send?phone=62213505351&text=Hai%20Admin%20Saya%20mau%20tanya%20tentang%20PPI%20Terima%20kasih",
    };
  },
  mounted() {
    this.getSalesByDivisi();
    this.getSalesByMitra();
    this.getPiutang();
    this.getKomoditi();
    this.getProduk();
    this.getMitra();

    am4core.useTheme(am4themes_animated);

    this.chart = am4core.create("chartdiv", am4charts.XYChart);

    let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "";
    valueAxis.renderer.minWidth = 35;

    let series = this.chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "category";
    series.name = "Value";
    series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
    series.columns.template.fillOpacity = 0.8;

    let columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 2;
    columnTemplate.strokeOpacity = 1;

    this.chart.cursor = new am4charts.XYCursor();
    this.chart.cursor.behavior = "zoomXY";

    this.chart.scrollbarX = new am4core.Scrollbar();
    this.chart.scrollbarY = new am4core.Scrollbar();

    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(
          "https://samantha.ptppi.co.id/api_salma/index.php/sales/sales_cabang",
          {
            params: {
              startDate: this.startDate,
              endDate: this.endDate,
              id: this.cabangId,
            },
          }
        );
        const responseData = response.data;
        console.log("Response Data:", response.data.total);

        if (!Array.isArray(responseData.data)) {
          console.error("Error: Data is not an array", responseData.data);
          return;
        }

        this.totalrp = this.formatNumberToShortString(response.data.total);
        this.unit_name = response.data.unit_name;

        const chartData = responseData.data.map((item) => ({
          category: item.to_char,
          value: item.rp,
        }));

        this.chart.data = chartData;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getSalesByDivisi() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          "https://samantha.ptppi.co.id/api_salma/index.php/sales/sales_divisi",
          {
            id: this.cabangId,
            search: this.search_table_items_sales_divisi,
          },
          { headers }
        );
        this.response = response.data;
        if (response.data.status === "fail") {
          this.items_sales_divisi = [];
        } else {
          this.items_sales_divisi = response.data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getSalesByMitra() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          "https://samantha.ptppi.co.id/api_salma/index.php/sales/sales_cabang",
          {
            id: this.cabangId,
            search: this.search_table_items_sales,
          },
          { headers }
        );
        this.response = response.data;
        if (response.data.status === "fail") {
          this.items_sales = [];
        } else {
          this.items_sales = response.data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getPiutang() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          "https://samantha.ptppi.co.id/api_salma/index.php/piutang/piutang_cabang",
          {
            id: this.cabangId,
            search: this.search_table_items_piutang,
          },
          { headers }
        );
        this.response = response.data;
        if (response.data.status === "fail") {
          this.items_piutang = [];
        } else {
          this.items_piutang = response.data.data;
          this.totalpiutang = this.formatNumberToShortString(
            response.data.total
          );
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getKomoditi() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          "https://samantha.ptppi.co.id/api_salma/index.php/sales/komoditi_cabang",
          {
            id: this.cabangId,
            search: this.search_table_items_komoditi,
          },
          { headers }
        );
        this.response = response.data;
        if (response.data.status === "fail") {
          this.items_komoditi = [];
        } else {
          this.items_komoditi = response.data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getProduk() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          "https://samantha.ptppi.co.id/api_salma/index.php/sales/produk_cabang",
          {
            id: this.cabangId,
            search: this.search_table_items_produk,
          },
          { headers }
        );
        this.response = response.data;
        if (response.data.status === "fail") {
          this.items_produk = [];
        } else {
          this.items_produk = response.data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getMitra() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          "https://samantha.ptppi.co.id/api_salma/index.php/sales/mitra_cabang",
          {
            id: this.cabangId,
            search: this.search_table_items_mitra,
          },
          { headers }
        );
        this.response = response.data;
        if (response.data.status === "fail") {
          this.items_mitra = [];
        } else {
          this.items_mitra = response.data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    formatNumberToShortString(number) {
      const trillion = 1e12; // 1 trillion
      const billion = 1e9; // 1 billion
      const million = 1e6; // 1 million

      if (number >= trillion) {
        return (number / trillion).toFixed(1) + " Triliun";
      } else if (number >= billion) {
        return (number / billion).toFixed(1) + " Miliar";
      } else if (number >= million) {
        return (number / million).toFixed(1) + " Juta";
      } else {
        return Math.round(Number(number)).toLocaleString("id-ID");
      }
    },
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<style>
.row_login {
  justify-content: center;
}

.whatsapp-button {
  background-color: #25d366;
  color: #fff;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 30px;
  text-decoration: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.whatsapp-button i {
  margin-right: 10px;
}

#chartdiv {
  width: 100%;
  height: 500px;
}
</style>
