<template>
  <v-app id="inspire">
    
    <v-app-bar app color="white" fixed>
      
      <img src="/assets/img/PPIIDFood.png" style="max-width:85px;">
      <!-- <img src="/assets/img/bdp.png" style="max-width:85px;"> -->
      
    </v-app-bar>

    
    <v-main>
      <router-view />
    </v-main>
    
  </v-app>
</template>

<script>
export default {
  components: {},
  data: () => ({
    
  }),
  methods: {
   
  },
  mounted() {
    
  },
  beforeDestroy() {
    
  }
};
</script>

<style>

</style>