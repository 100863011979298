<template>
  <v-app>
    <v-container fluid>
      <v-layout row row_login wrap>
        <v-flex xs12 md8 lg10>
          <v-card>
            <v-card-title>
              <h3>Profil Inventory : {{ unit_name }}</h3>
            </v-card-title>
            <v-card-subtitle>
              <h4>Tahun : {{ new Date().getFullYear() }}</h4>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-subtitle>
              <v-row>
                <v-col cols="4">
                  <h4>Total Inventory</h4>
                  <h2>
                    {{ totalrp }}
                  </h2>
                </v-col>
                <v-col cols="4">
                  <h4>Total Aging 0 - 30 Hari</h4>
                  <h2>
                    {{ total_1 }}
                  </h2>
                </v-col>
                <v-col cols="4">
                  <h4>Total Aging 31 - 60 Hari</h4>
                  <h2>
                    {{ total_2 }}
                  </h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <h4>Total Aging 61 - 90 Hari</h4>
                  <h2>
                    {{ total_3 }}
                  </h2>
                </v-col>
                <v-col cols="4">
                  <h4>Total Aging 91 - 120 Hari</h4>
                  <h2>
                    {{ total_4 }}
                  </h2>
                </v-col>
                <v-col cols="4">
                  <h4>Total Aging > 120 Hari</h4>
                  <h2>
                    {{ total_5 }}
                  </h2>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-divider></v-divider>
            <canvas ref="barChart"></canvas>
            <v-divider></v-divider>
            <v-card-title>
              <h4>Current</h4>
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" md="6">
                  <h4>List produk yang belum expired</h4>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="search_table_current"
                    append-icon="mdi-magnify"
                    label="Product Name"
                    single-line
                    hide-details
                    @input="getInventoryCurrent()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-simple-table fixed-header height="200px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="20%">Komoditi</th>
                          <th class="text-center" width="20%">Produk</th>
                          <th class="text-center" width="20%">Qty</th>
                          <th class="text-center" width="20%">Rp</th>
                          <th class="text-center" width="20%">Satuan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in produk_current"
                          :key="item.id"
                        >
                          <td style="font-size: small">
                            {{ item.product_category_name }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_name }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.qty) }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.value) }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_uom }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-title>
              <h4>Akan Expired 4 sd 6 Bulan</h4>
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" md="6">
                  <h4>List produk yang akan expired 4 sd 6 bulan</h4>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="search_table_produk_kurang_dari_6_bulan"
                    append-icon="mdi-magnify"
                    label="Product Name"
                    single-line
                    hide-details
                    @input="getInventory6Bulan()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-simple-table fixed-header height="200px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="20%">Komoditi</th>
                          <th class="text-center" width="20%">Produk</th>
                          <th class="text-center" width="20%">Qty</th>
                          <th class="text-center" width="20%">Rp</th>
                          <th class="text-center" width="20%">Satuan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in produk_kurang_dari_6_bulan"
                          :key="item.id"
                        >
                          <td style="font-size: small">
                            {{ item.product_category_name }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_name }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.qty) }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.value) }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_uom }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-title>
              <h4>Akan Expired 3 Months</h4>
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" md="6">
                  <h4>List produk yang akan expired kurang dari 3 bulan</h4>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="search_table_produk_kurang_dari_3_bulan"
                    append-icon="mdi-magnify"
                    label="Product Name"
                    single-line
                    hide-details
                    @input="getInventory3Bulan()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-simple-table fixed-header height="200px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="20%">Komoditi</th>
                          <th class="text-center" width="20%">Produk</th>
                          <th class="text-center" width="20%">Qty</th>
                          <th class="text-center" width="20%">Rp</th>
                          <th class="text-center" width="20%">Satuan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in produk_kurang_dari_3_bulan"
                          :key="item.id"
                        >
                          <td style="font-size: small">
                            {{ item.product_category_name }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_name }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.qty) }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.value) }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_uom }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-title>
              <h4>Akan Expired 2 Months</h4>
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" md="6">
                  <h4>List produk yang akan expired kurang dari 2 bulan</h4>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="search_table_produk_kurang_dari_2_bulan"
                    append-icon="mdi-magnify"
                    label="Product Name"
                    single-line
                    hide-details
                    @input="getInventory2Bulan()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-simple-table fixed-header height="200px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="20%">Komoditi</th>
                          <th class="text-center" width="20%">Produk</th>
                          <th class="text-center" width="20%">Qty</th>
                          <th class="text-center" width="20%">Rp</th>
                          <th class="text-center" width="20%">Satuan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in produk_kurang_dari_2_bulan"
                          :key="item.id"
                        >
                          <td style="font-size: small">
                            {{ item.product_category_name }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_name }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.qty) }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.value) }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_uom }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>

            <v-divider></v-divider>
            <v-card-title>
              <h4>Expired</h4>
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" md="6">
                  <h4>List produk yang sudah expired</h4>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="search_table_produk_expired"
                    append-icon="mdi-magnify"
                    label="Product Name"
                    single-line
                    hide-details
                    @input="getInventoryExpired()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-simple-table fixed-header height="200px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="20%">Komoditi</th>
                          <th class="text-center" width="20%">Produk</th>
                          <th class="text-center" width="20%">Qty</th>
                          <th class="text-center" width="20%">Rp</th>
                          <th class="text-center" width="20%">Satuan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in produk_expired"
                          :key="item.id"
                        >
                          <td style="font-size: small">
                            {{ item.product_category_name }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_name }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.qty) }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.value) }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_uom }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>

            <v-divider></v-divider>
            <v-card-title>
              <h4>Uncategorized</h4>
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" md="6">
                  <h4>List produk yang tidak ditentukan tanggal expirednya</h4>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="search_table_produk_uncategorized"
                    append-icon="mdi-magnify"
                    label="Product Name"
                    single-line
                    hide-details
                    @input="getInventoryUncategorized()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-simple-table fixed-header height="200px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="20%">Komoditi</th>
                          <th class="text-center" width="20%">Produk</th>
                          <th class="text-center" width="20%">Qty</th>
                          <th class="text-center" width="20%">Rp</th>
                          <th class="text-center" width="20%">Satuan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in produk_uncategorized"
                          :key="item.id"
                        >
                          <td style="font-size: small">
                            {{ item.product_category_name }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_name }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.qty) }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.value) }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_uom }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-title>
              <h4>Aging 0 - 30 Hari</h4>
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" md="6">
                  <h4>List produk yang sudah berada di gudang 0 - 30 Hari</h4>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="search_table_produk_aging_0_30"
                    append-icon="mdi-magnify"
                    label="Product Name"
                    single-line
                    hide-details
                    @input="getInventory_0_30()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-simple-table fixed-header height="200px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="20%">Komoditi</th>
                          <th class="text-center" width="20%">Produk</th>
                          <th class="text-center" width="20%">Qty</th>
                          <th class="text-center" width="20%">Rp</th>
                          <th class="text-center" width="20%">Satuan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in produk_aging_0_30"
                          :key="item.id"
                        >
                          <td style="font-size: small">
                            {{ item.product_category_name }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_name }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.qty) }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.value) }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_uom }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-title>
              <h4>Aging 31 - 60 Hari</h4>
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" md="6">
                  <h4>List produk yang sudah berada di gudang 31 - 60 Hari</h4>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="search_table_produk_aging_31_60"
                    append-icon="mdi-magnify"
                    label="Product Name"
                    single-line
                    hide-details
                    @input="getInventory_31_60()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-simple-table fixed-header height="200px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="20%">Komoditi</th>
                          <th class="text-center" width="20%">Produk</th>
                          <th class="text-center" width="20%">Qty</th>
                          <th class="text-center" width="20%">Rp</th>
                          <th class="text-center" width="20%">Satuan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in produk_aging_31_60"
                          :key="item.id"
                        >
                          <td style="font-size: small">
                            {{ item.product_category_name }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_name }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.qty) }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.value) }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_uom }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-title>
              <h4>Aging 61 - 90 Hari</h4>
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" md="6">
                  <h4>List produk yang sudah berada di gudang 61 - 90 Hari</h4>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="search_table_produk_aging_61_90"
                    append-icon="mdi-magnify"
                    label="Product Name"
                    single-line
                    hide-details
                    @input="getInventory_61_90()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-simple-table fixed-header height="200px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="20%">Komoditi</th>
                          <th class="text-center" width="20%">Produk</th>
                          <th class="text-center" width="20%">Qty</th>
                          <th class="text-center" width="20%">Rp</th>
                          <th class="text-center" width="20%">Satuan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in produk_aging_61_90"
                          :key="item.id"
                        >
                          <td style="font-size: small">
                            {{ item.product_category_name }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_name }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.qty) }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.value) }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_uom }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-title>
              <h4>Aging 91 - 120 Hari</h4>
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" md="6">
                  <h4>List produk yang sudah berada di gudang 91 - 120 Hari</h4>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="search_table_produk_aging_91_120"
                    append-icon="mdi-magnify"
                    label="Product Name"
                    single-line
                    hide-details
                    @input="getInventory_91_120()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-simple-table fixed-header height="200px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="20%">Komoditi</th>
                          <th class="text-center" width="20%">Produk</th>
                          <th class="text-center" width="20%">Qty</th>
                          <th class="text-center" width="20%">Rp</th>
                          <th class="text-center" width="20%">Satuan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in produk_aging_91_120"
                          :key="item.id"
                        >
                          <td style="font-size: small">
                            {{ item.product_category_name }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_name }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.qty) }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.value) }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_uom }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-title>
              <h4>Aging > 120 Hari</h4>
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" md="6">
                  <h4>
                    List produk yang sudah berada di gudang diatas 120 Hari
                  </h4>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="search_table_produk_aging_121"
                    append-icon="mdi-magnify"
                    label="Product Name"
                    single-line
                    hide-details
                    @input="getInventory_121()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-simple-table fixed-header height="200px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="20%">Komoditi</th>
                          <th class="text-center" width="20%">Produk</th>
                          <th class="text-center" width="20%">Qty</th>
                          <th class="text-center" width="20%">Rp</th>
                          <th class="text-center" width="20%">Satuan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in produk_aging_121"
                          :key="item.id"
                        >
                          <td style="font-size: small">
                            {{ item.product_category_name }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_name }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.qty) }}
                          </td>
                          <td style="text-align: right; font-size: small">
                            {{ formatNumberToShortString(item.value) }}
                          </td>
                          <td style="font-size: small">
                            {{ item.product_uom }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>
  
  <script>
import Chart from "chart.js/auto";
import axios from "axios";
import * as Cronitor from "@cronitorio/cronitor-rum";

Cronitor.load("e4f11e8ee1a7c8261ded903c2fa64d73");
Cronitor.track("InventoryCabang");
export default {
  props: ["cabangId"],
  data() {
    return {
      chart: null,
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Rp Berd Status Inventory",
            backgroundColor: "rgba(243, 113, 70, 0.2)",
            borderColor: "rgba(243, 113, 70, 1)",
            borderWidth: 1,
            data: [], // Contoh data penjualan
          },
        ],
      },
      search_table_produk_kurang_dari_6_bulan: "",
      produk_kurang_dari_6_bulan: [],

      search_table_produk_kurang_dari_3_bulan: "",
      produk_kurang_dari_3_bulan: [],

      search_table_produk_kurang_dari_2_bulan: "",
      produk_kurang_dari_2_bulan: [],

      search_table_produk_expired: "",
      produk_expired: [],

      search_table_produk_uncategorized: "",
      produk_uncategorized: [],

      search_table_current: "",
      produk_current: [],

      search_table_produk_aging_0_30: "",
      produk_aging_0_30: [],

      search_table_produk_aging_31_60: "",
      produk_aging_31_60: [],

      search_table_produk_aging_61_90: "",
      produk_aging_61_90: [],

      search_table_produk_aging_91_120: "",
      produk_aging_91_120: [],

      search_table_produk_aging_121: "",
      produk_aging_121: [],

      totalrp: 0,
      totalpiutang: 0,
      total_1: 0,
      total_2: 0,
      total_3: 0,
      total_4: 0,
      total_5: 0,
      unit_name: "",
      whatsappLink:
        "https://api.whatsapp.com/send?phone=62213505351&text=Hai%20Admin%20Saya%20mau%20tanya%20tentang%20PPI%20Terima%20kasih",
    };
  },
  mounted() {
    // Access cabangId here or perform any actions you need
    console.log("cabangId:", this.cabangId);
    this.fetchData();
    this.getInventoryCurrent();
    this.getInventory6Bulan();
    this.getInventory3Bulan();
    this.getInventory2Bulan();
    this.getInventoryExpired();
    this.getInventoryUncategorized();
    this.getInventory_0_30();
    this.getInventory_31_60();
    this.getInventory_61_90();
    this.getInventory_91_120();
    this.getInventory_121();
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(
          "https://samantha.ptppi.co.id/api_salma/index.php/inventory/inventory_cabang",
          {
            params: {
              startDate: this.startDate,
              endDate: this.endDate,
              id: this.cabangId,
            },
          }
        );
        const responseData = response.data;
        // Log the data to the console for debugging
        console.log("Response Data:", response.data.total);

        if (!Array.isArray(responseData.data)) {
          console.error("Error: Data is not an array", responseData.data);
          return;
        }

        // Continue with the rest of your logic
        this.chartData.labels = responseData.data.map((item) => item.locations);
        this.chartData.datasets[0].data = responseData.data.map((item) =>
          parseFloat(item.value)
        ); // convert rp to a numeric value
        this.totalrp = this.formatNumberToShortString(response.data.total);
        this.total_1 = this.formatNumberToShortString(response.data.total_1);
        this.total_2 = this.formatNumberToShortString(response.data.total_2);
        this.total_3 = this.formatNumberToShortString(response.data.total_3);
        this.total_4 = this.formatNumberToShortString(response.data.total_4);
        this.total_5 = this.formatNumberToShortString(response.data.total_5);
        // const formattedNumber = this.formatNumberToShortString(this.totalrp);
        // this.totalrp = formattedNumber;
        this.unit_name = response.data.unit_name;

        this.renderChart();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    renderChart() {
      const ctx = this.$refs.barChart.getContext("2d");
      this.chart = new Chart(ctx, {
        type: "bar",
        data: this.chartData,
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
    async getInventory6Bulan() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          "https://samantha.ptppi.co.id/api_salma/index.php/inventory/inventory_cabang_expired",
          {
            id: this.cabangId,
            awal: -183,
            akhir: -92,
            search: this.search_table_produk_kurang_dari_6_bulan,
          },
          { headers }
        );
        this.response = response.data;
        if (response.data.status === "fail") {
          this.produk_kurang_dari_6_bulan = [];
        } else {
          this.produk_kurang_dari_6_bulan = response.data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getInventory3Bulan() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          "https://samantha.ptppi.co.id/api_salma/index.php/inventory/inventory_cabang_expired",
          {
            id: this.cabangId,
            awal: -92,
            akhir: -31,
            search: this.search_table_produk_kurang_dari_3_bulan,
          },
          { headers }
        );
        this.response = response.data;
        if (response.data.status === "fail") {
          this.produk_kurang_dari_3_bulan = [];
        } else {
          this.produk_kurang_dari_3_bulan = response.data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getInventory2Bulan() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          "https://samantha.ptppi.co.id/api_salma/index.php/inventory/inventory_cabang_expired",
          {
            id: this.cabangId,
            awal: -31,
            akhir: -1,
            search: this.search_table_produk_kurang_dari_2_bulan,
          },
          { headers }
        );
        this.response = response.data;
        if (response.data.status === "fail") {
          this.produk_kurang_dari_2_bulan = [];
        } else {
          this.produk_kurang_dari_2_bulan = response.data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getInventoryExpired() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          "https://samantha.ptppi.co.id/api_salma/index.php/inventory/inventory_cabang_expired",
          {
            id: this.cabangId,
            awal: 0,
            akhir: 45452,
            search: this.search_table_produk_expired,
          },
          { headers }
        );
        this.response = response.data;
        if (response.data.status === "fail") {
          this.produk_expired = [];
        } else {
          this.produk_expired = response.data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getInventoryUncategorized() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          "https://samantha.ptppi.co.id/api_salma/index.php/inventory/inventory_cabang_expired",
          {
            id: this.cabangId,
            awal: 45452,
            akhir: 1000000,
            search: this.search_table_produk_uncategorized,
          },
          { headers }
        );
        this.response = response.data;
        if (response.data.status === "fail") {
          this.produk_uncategorized = [];
        } else {
          this.produk_uncategorized = response.data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getInventoryCurrent() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          "https://samantha.ptppi.co.id/api_salma/index.php/inventory/inventory_cabang_expired",
          {
            id: this.cabangId,
            awal: -1000000,
            akhir: -183,
            search: this.search_table_current,
          },
          { headers }
        );
        this.response = response.data;
        if (response.data.qty == 0) {
          this.produk_current = [];
        } else {
          this.produk_current = response.data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getInventory_0_30() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          "https://samantha.ptppi.co.id/api_salma/index.php/inventory/inventory_cabang_expired",
          {
            id: this.cabangId,
            awal: -1000000,
            akhir: 1000000,
            period_1: 1,
            search: this.search_table_produk_aging_0_30,
          },
          { headers }
        );
        this.response = response.data;
        if (response.data.status === "fail") {
          this.produk_aging_0_30 = [];
        } else {
          this.produk_aging_0_30 = response.data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getInventory_31_60() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          "https://samantha.ptppi.co.id/api_salma/index.php/inventory/inventory_cabang_expired",
          {
            id: this.cabangId,
            awal: -1000000,
            akhir: 1000000,
            period_2: 2,
            search: this.search_table_produk_aging_31_60,
          },
          { headers }
        );
        this.response = response.data;
        if (response.data.status === "fail") {
          this.produk_aging_31_60 = [];
        } else {
          this.produk_aging_31_60 = response.data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getInventory_61_90() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          "https://samantha.ptppi.co.id/api_salma/index.php/inventory/inventory_cabang_expired",
          {
            id: this.cabangId,
            awal: -1000000,
            akhir: 1000000,
            period_3: 3,
            search: this.search_table_produk_aging_61_90,
          },
          { headers }
        );
        this.response = response.data;
        if (response.data.status === "fail") {
          this.produk_aging_61_90 = [];
        } else {
          this.produk_aging_61_90 = response.data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getInventory_91_120() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          "https://samantha.ptppi.co.id/api_salma/index.php/inventory/inventory_cabang_expired",
          {
            id: this.cabangId,
            awal: -1000000,
            akhir: 1000000,
            period_4: 4,
            search: this.search_table_produk_aging_91_120,
          },
          { headers }
        );
        this.response = response.data;
        if (response.data.status === "fail") {
          this.produk_aging_91_120 = [];
        } else {
          this.produk_aging_91_120 = response.data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getInventory_121() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          "https://samantha.ptppi.co.id/api_salma/index.php/inventory/inventory_cabang_expired",
          {
            id: this.cabangId,
            awal: -1000000,
            akhir: 1000000,
            period_5: 5,
            search: this.search_table_produk_aging_121,
          },
          { headers }
        );
        this.response = response.data;
        if (response.data.status === "fail") {
          this.produk_aging_121 = [];
        } else {
          this.produk_aging_121 = response.data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    formatNumberToShortString(number) {
      const trillion = 1e12; // 1 trillion
      const billion = 1e9; // 1 billion
      const million = 1e6; // 1 million

      if (number >= trillion) {
        return (number / trillion).toFixed(1) + " Triliun";
      } else if (number >= billion) {
        return (number / billion).toFixed(1) + " Miliar";
      } else if (number >= million) {
        return (number / million).toFixed(1) + " Juta";
      } else {
        return Math.round(Number(number)).toLocaleString("id-ID");
      }
      // const inputNumber = 722755674511;
      // const formattedNumber = formatNumberToShortString(inputNumber);
      //console.log(number); // Output: "722.8B"
    },
  },
  watch: {
    chartData: {
      handler() {
        if (this.chart) {
          this.chart.destroy();
        }
        this.renderChart();
      },
      deep: true,
    },
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
};
</script>

<style>
.row_login {
  justify-content: center;
}

.whatsapp-button {
  background-color: #25d366; /* Warna latar belakang WhatsApp */
  color: #fff; /* Warna teks */
  display: inline-block;
  padding: 10px 20px;
  border-radius: 30px; /* Untuk membuat tombol berbentuk bulat */
  text-decoration: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Efek bayangan */
  z-index: 9999;
}

.whatsapp-button i {
  margin-right: 10px; /* Jarak antara ikon dan teks */
}
</style>
  